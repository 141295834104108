<template>
  <v-container class="fill-height">
    <!-- <WidgetAlert /> -->
    <v-row dense justify="center">
      <v-col cols="12" class="pb-2 pt-md-8" align-self="start">
        <h2
          class="header font-weight-bold mb-2 white--text text-center mx-auto"
        >
          LOCALITZA BUS
        </h2>
        <p
          class="subheading font-weight-regular white--text text-center mx-auto"
        >
          Localitza el bus més proper a la teva ubicació
        </p>
      </v-col>
    </v-row>

    <v-row v-if="!map" justify="center" dense>
      <v-col cols="12" md="8" class="text-center">
        <p class="subheading font-weight-regular mb-4 white--text text-center">
          Cerca el Bus més proper a la teva ubicació a les parades més properes
          d'on et trobes actualment.<br />
          Fes click al botó "LOCALITZA'M" a continuació i et mostrarem les
          parades més properes.
        </p>

        <v-alert
          type="warning"
          v-model="showAlert"
          transition="scale-transition"
          color="amber darken-4"
          class="my-5 w-md-75"
        >
          <v-row dense>
            <v-col cols="12" md="10" class="grow">
              <h3>ATENCIÓ!</h3>
              <p class="mb-0 text-justify">
                Aquesta funcionalitat nomès funcionarà si permets que l'App
                pugui usar la geolocalització del teu dispositiu. En prémer el
                botó "LOCALITZA'M" es mostrarà una alerta per a que acceptis o
                no l'accès a geolocalitzar la teva ubicació.
              </p>
            </v-col>
            <v-col cols="12" md="2" class="shrink px-md-3">
              <v-btn
                block
                class="mx-md-2"
                outlined
                @click.stop="showAlert = false"
                >OK</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>

        <v-btn
          x-large
          color="success"
          dark
          class="mt-2"
          :loading="loading"
          :disabled="loading"
          @click="loader = 'loading' && showUserMarker()"
        >
          <v-icon dark class="mr-2">fa fa-map-marker-alt</v-icon>
          Localitza'm
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else dense>
      <v-col cols="12" md="8">
        <div id="map">
          <l-map
            v-if="showMap"
            ref="mapa"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            style="z-index: 4 !important;"
            @ready="map = true"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <!-- Stops Markers -->
            <l-marker
              :key="i"
              v-for="(m, i) in markers"
              :lat-lng="m.position"
              :ref="'mrk' + i"
            >
              <l-popup>
                <div @click="address = !address">
                  <span class="body-1 font-weight-bold">{{ m.infoText }}</span>
                  <p v-if="m.address" class="py-0 my-0">
                    {{ m.address }}
                  </p>
                </div>
              </l-popup>
            </l-marker>
            <!-- User Position -->
            <l-marker
              v-if="userLocationMarker.position"
              :lat-lng="this.userLocationMarker.position"
            >
              <l-tooltip :options="{ permanent: true, interactive: false }">
                <v-icon color="success" small>fa-user-circle</v-icon> TÚ
              </l-tooltip>
            </l-marker>

            <!-- <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
            </gmap-info-window>

            <gmap-marker
              :key="i"
              v-for="(m, i) in markers"
              :position="m.position"
              :clickable="true"
              @click="toggleInfoWindow(m, i)"
            ></gmap-marker> -->
          </l-map>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <v-card>
          <div class="text-center primary--text py-2 mt-xs-3">
            PARADES MÉS PROPERES
          </div>
          <div class="pa-2 px-5">
            <v-btn
              color="info"
              block
              class="mx-auto mb-2"
              @click="renewUserMarker()"
            >
              Localitza'm
            </v-btn>
            <v-btn
              color="success"
              block
              class="mx-auto mb-2"
              :disabled="userPosition === null || locationStops.length > 0"
              @click="getLocationStops()"
            >
              Obtenir Parades
            </v-btn>
          </div>
          <div v-if="locationStops.length > 0">
            <v-list
              v-for="(stop, index) in locationStops"
              :key="index"
              three-line
              class="mt-0 py-0"
              dense
            >
              <v-list-item link @click="showSelectedMarker(stop, index)">
                <v-list-item-icon>
                  <v-icon color="red darken-2">
                    fas fa-map-marker-alt
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    Parada: {{ stop.infoText }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="py-0">
                    {{ stop.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="pa-2">
            <v-alert type="error" v-if="showError" class="my-2">
              {{ errorMessage }}
            </v-alert>
            <v-alert type="info" v-model="stopsNotFoundMsg" class="ma-2">
              No hem localitzat cap parada en un radi de {{ radio }} m. en la
              teva ubicació.
            </v-alert>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Location from "@/api/location";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
// import WidgetAlert from "@/components/WidgetAlert";

export default {
  name: "localitzabus",

  data: () => ({
    logged: false,

    token: null,
    user: null,
    userPosition: null,
    // userCoords: latLng(this.userPosition.lat, this.userPosition.lng),

    showAlert: false,
    showError: false,
    errorMessage: "Woops! S'ha produït un error en la obtenció de dades",

    loader: null,
    loading: false,

    map: false,

    showMap: true,

    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

    // iconUser: icon({
    //   iconUrl: "@/assets/img/bus-stop.png",
    //   iconSize: [32, 37],
    //   iconAnchor: [16, 37]
    // }),

    initialMarker: {
      // Estació autobusos Vendrell
      position: latLng(41.2180007, 1.5359283),
      infoText: "Estació Autobusos El Vendrell"
    },

    center: latLng(41.2180007, 1.5359283),

    // withPopup: latLng(41.2180007, 1.5359283),
    // withTooltip: latLng(41.2183, 1.5363),
    currentZoom: 15,
    currentCenter: latLng(41.2183, 1.5363),
    showParagraph: false,

    zoom: 15,

    mapOptions: {
      zoomSnap: 0.5,
      scrollWheelZoom: false
    },

    markers: [],

    address: false,

    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,

    infoOptions: {
      content: "",
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35
      }
    },

    radio: 1000, // Default radio for markers respect user position

    userLocationMarker: {
      position: null,
      infoText: ""
      // icon: this.svgMarker
    },

    locationStops: [],

    stopsNotFoundMsg: false
  }),

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;

      this.map = true;
    }
  },

  computed: {
    userCoords: function() {
      // console.log(this.userLocationMarker.position);
      if (this.userLocationMarker.position) {
        return latLng(this.userLocationMarker.position);
      } else return undefined;
    }
  },

  created() {
    this.showAlert = true;
    this.user = this.$store.getters.getUserInfo;
    this.token = this.$store.state.token;
  },

  mounted() {
    this.$nextTick(() => {
      this.markers.push(this.initialMarker);
    });
  },

  methods: {
    mapIsReady() {
      console.log("map is ready!");
    },

    getUserLocation() {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            const text =
              "<strong>La teva posició actual es: </strong> <br /> " +
              "Lat: " +
              pos.lat +
              " | " +
              "Lng: " +
              pos.lng;

            // console.log(JSON.stringify(pos));
            // infoWindow.setPosition(pos);
            // infoWindow.setContent("Location found.");
            // infoWindow.open(map);
            // map.setCenter(pos);
            this.center = latLng(pos.lat, pos.lng);
            this.userLocationMarker.position = [pos.lat, pos.lng];
            this.userLocationMarker.infoText = text;
            this.userPosition = { lat: pos.lat, lng: pos.lng };
          },
          () => {
            console.log("Some error has occurred trying to get your position");
            this.errorMessage =
              "S'ha produït un error intentant la localització. Comprova que permets l'ús de la geolocalització en el teu dispositiu.";
            this.showError = true;
          }
        );
      } else {
        // Browser doesn't support Geolocation
        console.log("Browser doesn't support Geolocation");
      }
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },

    showUserMarker() {
      this.getUserLocation();
      console.log(
        "La teva posició es: " + JSON.stringify(this.userLocationMarker)
      );
      // this.userLocationMarker = [this.userLocationMarker];
    },

    renewUserMarker() {
      this.markers = [];
      this.locationStops = [];
      // this.infoWinOpen = false;
      this.showUserMarker();
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      if (marker.address) {
        this.infoOptions.content =
          "<strong>" +
          marker.infoText +
          "</strong>" +
          "<br />" +
          marker.address;
      } else {
        this.infoOptions.content = marker.infoText;
      }

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    async getLocationStops() {
      this.showError = false;
      const userData = {
        token: this.token,
        user_id: this.user.userId,
        latitude: this.userPosition.lat,
        longitude: this.userPosition.lng,
        radio: this.radio
      };
      await Location.getLocationStops(userData)
        .then(response => {
          const res = response.data;
          if (res.length != 0 && res.length != undefined) {
            const stops = res;
            // console.log("stops:" + stops);
            this.stopsNotFoundMsg = false;
            for (let i = 0; i < stops.length; i++) {
              let stop = stops[i];
              let locationStop = {
                position: latLng(stop.latitude, stop.longitude),
                infoText: stop.stop,
                address: stop.address
              };
              this.locationStops.push(locationStop);
            }
          } else {
            console.log("No stops returned" + res);
            this.stopsNotFoundMsg = true;
          }
          this.loading = false;
          this.setStopsOnMap();
        })
        .catch(error => {
          this.error = error;
          this.loading = false;
          this.errorMessage =
            "Opps! Sembla que s'ha produït un error en la obtenció de dades.";
          this.showError = true;
          this.stopsNotFoundMsg = false;
          console.log(error);
        });
    },

    setStopsOnMap() {
      this.infoWinOpen = false;
      this.markers = this.locationStops;
    },

    showSelectedMarker(marker, idx) {
      // console.log(marker.position);
      this.center = marker.position;
      console.log("Marker:" + idx);
    }
  }
};
</script>
<style scoped lang="scss">
@import "~vuetify/src/components/VStepper/_variables.scss";

#map {
  width: 100%;
  height: 600px;
  overflow: auto;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  #map {
    width: 100%;
    height: 50vh;
    overflow: auto;
  }
}
</style>
