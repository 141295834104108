// src/api/location.js
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL + "/location" || "apiv1/location";

export default {
  getLocationStops(userData) {
    return axios.get(API_URL + "/stops", {
      params: {
        token: userData.token,
        user_id: userData.user_id,
        latitude: userData.latitude,
        longitude: userData.longitude,
        radio: userData.radio
      }
    });
  }
};
